
    var riot = require('riot')
    
riot.tag2('searchbutton', '<a onclick="{showsearch}"><i class="fa fa-search"></i></a>', 'searchbutton a { display: block; padding: 14px 15px 6px 15px; line-height: 20px; }', '', function(opts) {
this.on('mount', function() {
  return console.log("search box");
});

this.showsearch = (function(_this) {
  return function() {
    var sb;
    sb = document.querySelector('.search-wrapper');
    return sb.classList.toggle('show');
  };
})(this);
});
    
  