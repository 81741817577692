
    var riot = require('riot')
    
riot.tag2('virtual-map', '<h2>{titles[floor]}</h2> <div class="vmap-wrapper"><img riot-src="/images/assets/virtual-map/vmap-floor-{floor}.jpg" class="img-responsive"> <div each="{ib in infobuttons[floor]}" riot-style="top:{ib.top || 0}%;left:{ib.left || 0}%" onclick="{openModal.bind(null,ib.modal)}" class="infobutton"> <div class="infobutton-pulse"></div><img src="/images/assets/virtual-map/infobutton.svg" class="infobutton-circle"> </div> </div> <div class="areakey"> <h4>Area Key</h4> <ul class="list-unstyled {floor == 0 ? \'split-list\' : \'\'}"> <li each="{akey in areakeys[floor]}" class="vmap-{akey.keycolour}">{akey.title}</li> </ul> </div><yield></yield>', '', '', function(opts) {
this.floor = opts.floor || 0;

this.titles = ["Ground Floor", "First Floor", "Second Floor"];

this.areakeys = [
  [
    {
      title: "Design & Technology",
      keycolour: "red"
    }, {
      title: "Art & Design",
      keycolour: "orange"
    }, {
      title: "Performance",
      keycolour: "yellow"
    }, {
      title: "Physical Education",
      keycolour: "green1"
    }, {
      title: "Achievement Corridor",
      keycolour: "green2"
    }, {
      title: "P: Principal office",
      keycolour: "grey"
    }, {
      title: "Care, Guidance & Support",
      keycolour: "grey"
    }
  ], [
    {
      title: "Mathematics",
      keycolour: "red"
    }, {
      title: "English",
      keycolour: "yellow"
    }, {
      title: "Modern Foreign Languages",
      keycolour: "purple"
    }, {
      title: "History",
      keycolour: "green2"
    }, {
      title: "Geography",
      keycolour: "green2"
    }
  ], [
    {
      title: "Computing, ICT & Business",
      keycolour: "red"
    }, {
      title: "Science",
      keycolour: "yellow"
    }, {
      title: "Science",
      keycolour: "purple"
    }
  ]
];

this.infobuttons = [
  [
    {
      top: "41",
      left: "12",
      modal: "vmap_modal_0_0"
    }, {
      top: '24.5',
      left: '22.5',
      modal: "vmap_modal_0_1"
    }, {
      top: '36',
      left: '33.5',
      modal: "vmap_modal_0_2"
    }, {
      top: '52',
      left: '89.5',
      modal: "vmap_modal_0_3"
    }, {
      top: '81.5',
      left: '51.5',
      modal: "vmap_modal_0_4"
    }, {
      top: '81.5',
      left: '25.5',
      modal: "vmap_modal_0_5"
    }, {
      top: '56.5',
      left: '36.5',
      modal: "vmap_modal_0_6"
    }, {
      top: '61.5',
      left: '46.3',
      modal: "vmap_modal_0_7"
    }, {
      top: '59.5',
      left: '67.5',
      modal: "vmap_modal_0_8"
    }, {
      top: '83',
      left: '33.1',
      modal: "vmap_modal_0_9"
    }, {
      top: '81.5',
      left: '15.5',
      modal: "vmap_modal_0_10"
    }
  ], [
    {
      top: '48',
      left: '18.5',
      modal: "vmap_modal_1_0"
    }, {
      top: '36',
      left: '50.3',
      modal: "vmap_modal_1_1"
    }, {
      top: '36',
      left: '82',
      modal: "vmap_modal_1_2"
    }, {
      top: '85.5',
      left: '78.3',
      modal: "vmap_modal_1_3"
    }, {
      top: '85.5',
      left: '28',
      modal: "vmap_modal_1_4"
    }, {
      top: '69',
      left: '34',
      modal: "vmap_modal_1_5"
    }, {
      top: '63.5',
      left: '50',
      modal: "vmap_modal_1_6"
    }
  ], [
    {
      top: '53.5',
      left: '15.8',
      modal: "vmap_modal_2_0"
    }, {
      top: '53.5',
      left: '50.5',
      modal: "vmap_modal_2_1"
    }, {
      top: '46',
      left: '86.2',
      modal: "vmap_modal_2_2"
    }, {
      top: '82',
      left: '50.5',
      modal: "vmap_modal_2_3"
    }
  ]
];

this.on('mount', (function(_this) {
  return function() {
    return console.log("interactive map", _this.floor);
  };
})(this));

this.openModal = (function(_this) {
  return function(modal) {
    if (_this.tags[modal]) {
      return _this.tags[modal].show();
    }
  };
})(this);
});
    
  