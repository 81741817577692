
    var riot = require('riot')
    
riot.tag2('section-buttons', '<div class="row"> <div each="{sb,i in sectionbuttons}" class="col-xs-12 col-md-4 section-button {\'.section-button-\'+(i+1)}"><a href="{sectionbuttons[i].link}"> <h4>{sectionbuttons[i].title}</h4> <p>Click here to view more information</p></a></div> </div>', '', '', function(opts) {
this.sectionbuttons = [
  {
    title: "",
    link: "#"
  }, {
    title: "",
    link: "#"
  }, {
    title: "",
    link: "#"
  }
];

this.on('mount', (function(_this) {
  return function() {
    return _this.getDlists();
  };
})(this));

this.getDlists = (function(_this) {
  return function() {
    var hctas;
    hctas = document.querySelectorAll('.header-ctas .cta');
    [].map.call(hctas, function(cta, index) {
      var link;
      if (cta.querySelector('.dl_quicklinks h4 a')) {
        link = cta.querySelector('.dl_quicklinks h4 a');
        _this.sectionbuttons[index].title = link.innerText;
        return _this.sectionbuttons[index].link = link.href;
      }
    });
    return _this.update();
  };
})(this);
});
    
  