
    var riot = require('riot')
    
riot.tag2('gtranslate', '<div onclick="{translate}" class="translate-buttons"><img src="/images/assets/translate-icon.svg" height="15" title="translate" class="translate-icon"><yield></yield> </div>', 'iframe.goog-te-banner-frame { top: -10000px; } .translate-buttons { position: relative; overflow: hidden; text-align: center; width: 26px; height: 26px; background: #00d6ed; border-radius: 50%; background-position: center; margin: 7px 3px -8px 3px; line-height: 26px; } #google_translate_element .goog-te-combo { position: absolute; top: 0; right: 0; opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } html.translated-ltr body { top: 0 !important; }', '', function(opts) {
this.translate = (function(_this) {
  return function() {
    return console.log("translating");
  };
})(this);
});
    
  