addControls = ->
  [].slice.call(document.querySelectorAll(".duoslideshow")).forEach (slideshow)->
    next = document.createElement("div")
    next.className = "next fa fa-chevron-right"
    next.addEventListener "click",-> moveNext(slideshow)
    slideshow.appendChild(next)
    prev = document.createElement("div")
    prev.className = "prev fa fa-chevron-left"
    prev.addEventListener "click",-> movePrev(slideshow)
    slideshow.appendChild(prev)

addPagers = ->
  [].slice.call(document.querySelectorAll(".duoslideshow")).forEach (slideshow)->
    pager = document.createElement("div")
    pager.className = "pager"
    slides = [].slice.call(slideshow.querySelectorAll(".duoslideshow .duoslide"))
    slides.forEach (slide,idx)->
      pagerButton = document.createElement("div")
      pagerButton.className = "pager-button"
      pagerButton.addEventListener "click",-> gotoSlide(slideshow,idx)
      pager.appendChild(pagerButton)
    slideshow.appendChild(pager)
    pagerSetActive(slideshow)

pagerSetActive = (slideshow)->
  slides = [].slice.call(slideshow.querySelectorAll(".duoslide"))
  pagerButtons = [].slice.call(slideshow.querySelectorAll(".pager-button"))
  pagerButtons.forEach (b,idx)-> 
    b.classList.add("active") if slides[idx].className.indexOf("active")>-1 && b.className.indexOf("active")==-1
    b.classList.remove("active") if slides[idx].className.indexOf("active")==-1 && b.className.indexOf("active")>-1

nextSlide = (active)->
  newactive = active.nextElementSibling
  return newactive if newactive && newactive.className.indexOf("duoslide")>-1
  return nextSlide(newactive) if newactive

prevSlide = (active)->
  newactive = active.previousElementSibling
  return newactive if newactive && newactive.className.indexOf("duoslide")>-1
  return prevSlide(newactive) if newactive

preventDoubleAnimation = (slideshow)->
  return true if slideshow.className.indexOf("active")>-1
  slideshow.classList.add("active")
  setTimeout => 
    slideshow.classList.remove("active")
  ,1000
  return false

gotoSlide = (slideshow,idx)->
  return if preventDoubleAnimation(slideshow)
  slides = [].slice.call(slideshow.querySelectorAll(".duoslide"))
  active = slideshow.querySelector(".duoslide.active")
  active.classList.remove("active")
  slides[idx].classList.add("active")
  pagerSetActive(slideshow)
  
moveNext = (slideshow)->
  return if preventDoubleAnimation(slideshow)
  slides = [].slice.call(slideshow.querySelectorAll(".duoslideshow .duoslide"))
  if slides?.length
    active = slideshow.querySelector(".duoslideshow .duoslide.active") # get current active
    if active 
      newactive = nextSlide(active)
      active.classList.remove("active") # remove active class
    if newactive
      newactive.classList.add("active")
    else
      slides[0].classList.add("active")
  pagerSetActive(slideshow)

movePrev = (slideshow)->
  return if preventDoubleAnimation(slideshow)
  slides = [].slice.call(slideshow.querySelectorAll(".duoslideshow .duoslide"))
  if slides?.length
    active = slideshow.querySelector(".duoslideshow .duoslide.active") # get current active
    if active 
      newactive = prevSlide(active)
      active.classList.remove("active") # remove active class
    if newactive
      newactive.classList.add("active")
    else
      slides[slides.length-1].classList.add("active")
  pagerSetActive(slideshow)

if !window.location.search?.match (/editmode/)
  addControls()
  addPagers()
  setInterval ->
    [].slice.call(document.querySelectorAll(".duoslideshow")).forEach (slideshow)->
      moveNext(slideshow)
  ,9000