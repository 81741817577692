<script>
  import {onMount} from 'svelte';
  import MomentPreciseRangePlugin from 'moment-precise-range-plugin'
  import moment from 'moment';
  export let deadline,days,hours,minutes,seconds,timer,datediff,datecheck=false;

  onMount(async () =>{
    if(!deadline){deadline = setDeadline();}
    // initializeCount(deadline);
    timer = setInterval(() => {
      initializeCount(deadline);
    },1000);
  });

  function setDeadline(){
    let date = new Date();
    let newDate = new Date(date.setMonth(date.getMonth()+1));
    return newDate;
  }

  function initializeCount(deadline){
    let start = moment()
    let end = moment(Date.parse(deadline))
    datediff = moment.preciseDiff(start,end,true)
    datecheck = start.isBefore(end)
    // console.log(datediff,datecheck)
    // let t = Date.parse(deadline) - Date.parse(new Date);
    // let time = new moment();
    // console.log("moment",diff);
    // if(t <= 0){
      // if(t==0){window.location.reload();}
      // return;
    // }
    // days = Math.floor(t / (1000 * 60 * 60 * 24));
    // seconds = ('0' + Math.floor(t / 1000 % 60)).slice(-2);
    // minutes = ('0' + Math.floor(t / 1000 / 60 % 60)).slice(-2);
    // hours = ('0' + Math.floor(t / (1000 * 60 * 60) % 24)).slice(-2);
  }

</script>

<div class="duo-countdown">
  {#if datediff && datediff.seconds != undefined && datecheck == true}
    {#if datediff.days > 0 || datediff.hours > 0 || datediff.minutes > 0}
      <span class="duo-countdown-metric">{("0"+datediff.months).slice(-2)} <small>month{#if datediff.months != 1}s{/if}</small></span>  
      <span class="separator">:</span>
      <span class="duo-countdown-metric">{("0"+datediff.days).slice(-2)} <small>day{#if datediff.days != 1}s{/if}</small></span>
      <span class="separator">:</span>
      <span class="duo-countdown-metric">{("0"+datediff.hours).slice(-2)} <small>hour{#if datediff.hours != 1}s{/if}</small></span>
      <!-- <span class="duo-countdown-metric">{datediff.minutes} <small>minute{#if datediff.minutes != 1}s{/if}</small></span> -->
    {/if}
    {#if datediff.days == 0 && datediff.hours == 0 && datediff.minutes == 0}
      <span class="duo-countdown-metric">{datediff.seconds} <small>second{#if datediff.seconds != 1}s{/if}</small></span>
    {/if}
  {:else}
    <span class="duo-countdown-metric">00</span>
    <span class="separator">:</span>
    <span class="duo-countdown-metric">00</span>
    <span class="separator">:</span>
    <span class="duo-countdown-metric">00</span>
  {/if}
</div>

<style>
  .duo-countdown-metric{
    font-size:50px;
  }
</style>