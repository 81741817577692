
    var riot = require('riot')
    
riot.tag2('trustlogin', '<form onsubmit="{login}" class="form-horizontal"> <forminput name="email" label="Email" onupdate="{inputupdate}" isrequired type="email"></forminput> <forminput type="password" name="password" label="Password" onupdate="{inputupdate}" isrequired></forminput> <div class="pull-right"><a href="#" onclick="{showforgotPassword}">Reset password</a></div> <input type="submit" value="" style="opacity:0"> </form> <forgot-password-modal></forgot-password-modal>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on("mount", function() {
  this.credentials = {};
  return this.redir = this.opts.domain + "/" || null;
});

this.on("update", function() {
  return this.userid = +opts.userid;
});

this.redir = this.opts.redirect || null;

this.showlogin = (function(_this) {
  return function() {
    return _this.tags.loginmodal.show();
  };
})(this);

this.inputupdate = (function(_this) {
  return function(val) {
    _this.credentials[val.name] = val.value;
    return _this.update();
  };
})(this);

this.showForgotPassword = (function(_this) {
  return function(e) {
    e.preventDefault();
    _this.tags['forgot-password-modal'].trigger('show');
    return _this.tags['forgot-password-modal'].forgotpassword.user.email = _this.credentials.email;
  };
})(this);

this.login = (function(_this) {
  return function(ev) {
    if (ev != null) {
      ev.preventDefault();
    }
    if (_this.credentials.email.match(/@/) && _this.credentials.password.length > 6) {
      return xhr("POST", "/duocms/api/login", _this.credentials, function(res) {
        if (res.status >= 400) {
          _this.update({
            error: res.body.message
          });
          return setTimeout((function() {
            return _this.update({
              error: null
            });
          }), 3000);
        } else if (res.status === 200) {
          _this.user = res.body;
          _this.userid = res.body.data.id;
          if (_this.redir) {
            window.location.href = _this.redir;
          } else {
            window.location.reload();
          }
          return _this.update();
        }
      });
    }
  };
})(this);

this.logout = (function(_this) {
  return function(e) {
    e.preventDefault();
    return xhr("GET", "/duocms/api/logout", null, function(res) {
      _this.user = null;
      _this.userid = null;
      _this.update();
      window.location.pathname = '/';
      window.location.reload();
      return null;
    });
  };
})(this);
});
    
  