var $, jQuery, mmutationObserver, mo, riot, slideshows;

window.riot = riot = require('riot');

let isEditMode = window.location.search.match(/editmode=true/)

let componentCss = ""
document.querySelectorAll("link").forEach( tag => {
  let url = tag.getAttribute("href")
  if(url.startsWith("/resources/css/site")){
    componentCss = url
  }
})

import {component} from "duo-kit";
import Countdown from "./components/countdown.svelte";
import Ctamodal from "./components/cta-modal.svelte";
import Eventcarousel from "./components/event-carousel.svelte";
import SocialSelect from "./components/socialselect.svelte";
component({component:Countdown,tagname:"duo-countdown",href:componentCss,shadow:isEditMode,attributes:["deadline"]});
component({component:Ctamodal,tagname:"cta-modal",href:componentCss,shadow:isEditMode});
component({component:Eventcarousel,tagname:"event-carousel",href:componentCss,shadow:isEditMode});
component({component:SocialSelect,tagname:"social-selector",href:componentCss,shadow:isEditMode});

// scrollChange({ watch:".videobanner",update:"body",threshold:0.5,classBelow:"shrink", mode:"toggle"}); // toggles shrink nav when h1 is scrolled away

require('riot-kit/lib/slideshow');
require('riot-kit/lib/auth');
require('riot-kit/lib/modal');
require('riot-kit/lib/gmap');
require('riot-kit/lib/dateformat');
require('riot-kit/lib/dropdown');

$ = jQuery = window.jQuery = window.$ = require("jquery");

require('owl.carousel');
require('./tags/twitter.tag');
require('./tags/navigation.tag');
require('./tags/eventcalendar.tag');
require('./tags/datepicker.tag');
require('./libs/slideshow.coffee');
require('./tags/searchbutton.tag');
require('./tags/section-buttons.tag');
require('./tags/trustlogin.tag');
require('./tags/ical.tag');
require('./tags/gtranslate.tag');
require('./tags/virtualmap.tag');
require('./tags/ytmodal.tag');
require('./tags/videomodal.tag');
require('./tags/recaptcha.tag');

riot.mount('*');

document.addEventListener('duocms:updated', function() {
  return riot.mount('slideshow');
});

document.querySelectorAll(".img-wrap").forEach( el => el.style.shapeOutside = `url(${el.getAttribute("src")})` )

var vidlinks;

vidlinks = document.querySelectorAll('.figure-videolink');

if (vidlinks) {
  console.log(vidlinks);
  [].map.call(vidlinks, (function(_this) {
    return function(vidlink) {
      var link;
      link = vidlink.querySelector('a[href]');
      if (link) {
        console.log(link);
        return vidlink.addEventListener('click', function(e) {
          console.log("click");
          e.preventDefault();
          return link.click();
        });
      }
    };
  })(this));
}

setTimeout((function(_this) {
  return function() {
    return $(document).ready(function() {
      return $('.twitter-carousel').owlCarousel({
        items: 3,
        pagination: true,
        nav: true,
        margin: 2,
        responsive: {
          0: {
            items: 1
          },
          992: {
            items: 3
          }
        }
      });
    });
  };
})(this), 500);

setTimeout((function(_this) {
  return function(){
    return($(document).ready(function(){
      return $('.dl_socials').owlCarousel({
        center:true,
        items:3,
        pagination:true,
        nav:true,
        loop:true,
        margin:0,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{0:{items:1},768:{items:3}}
      })
    }))
  }
})(this),1)

setTimeout((function(_this) {
  return function() {
    return $(document).ready(function() {
      return $('.modal-carousel').owlCarousel({
        items: 1,
        pagination: true
      });
    });
  };
})(this), 1000);

slideshows = document.querySelectorAll('.duoslideshow');

if (slideshows.length > 0) {
  [].map.call(slideshows, (function(_this) {
    return function(ss) {
      var slidecount;
      slidecount = ss.querySelectorAll('.duoslide');
      if (slidecount.length === 1) {
        return ss.classList.add('singleslide');
      }
    };
  })(this));
}

mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

mo = new mmutationObserver(function(mutations) {
  var m, mutation, results;
  m = 0;
  results = [];
  while (m < mutations.length) {
    mutation = mutations[m];
    if (mutation.type === "attributes" && mutation.target.className.match(/navshowing/)) {
      [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach(function(subnav) {
        return subnav.addEventListener('click', function(e) {
          var subsecs;
          if (subnav.getAttribute('riot-tag') === "dropdown" && subnav.className.match(/open/)) {
            console.log("subnav click");
            e.preventDefault();
            subnav.querySelector('a,i').addEventListener('click', function(e) {
              return window.location.href = subnav.querySelector('a').href;
            });
            subsecs = subnav.querySelectorAll('.dropdown-menu a');
            if (subsecs.length > 0) {
              return [].slice.call(subsecs).map((function(_this) {
                return function(sec) {
                  return sec.addEventListener('click', function(e) {
                    return window.location.href = sec.href;
                  });
                };
              })(this));
            }
          }
        });
      });
    }
    results.push(m++);
  }
  return results;
});

mo.observe(document.querySelector('body'), {
  attributes: true
});
