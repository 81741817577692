<script>
  import {onMount,onDestroy} from "svelte"
  import smoothscroll from 'smoothscroll-polyfill';
  smoothscroll.polyfill();
  let node,tag,tags,events=[],elem,active=0,scrollDebounce,nextEventTime,pageedit
  let star = '<span class="event-star">'

  onMount(()=>{
    // let editmode = getParams('cms')
    let editmode = location.pathname.search(/\/cms\//g)>-1 ? true : false
    if(editmode){ tag = node.getRootNode().host } else { tag = node }

    pageedit = document.querySelector('body').classList.contains('editmode') ? true : false

    if(!pageedit){checkCal(tag) || []}

  });

  const processTags = (t)=>{
    console.log("processing tags",t)
    tags = t
    tags.forEach((ev,id)=>{
      let month = ev.querySelector('.bigmonth').innerText.replaceAll('\n','')
      let date = ev.querySelector('.bigdate').innerText.replaceAll('\n','')
      let title = ev.querySelector('.title').innerText.replaceAll('\n','')
      let description = ev.querySelector('.description') ? ev.querySelector('.description').innerText.replaceAll('\n','') : ''
      events[id] = {'month':month,'date':date,'title':title,'description':description}
    })
    nextEvent()
  }

  const checkCal = (cal)=>{
    let timer
    // console.log("timer defined",timer,cal)
    if(timer !== null && timer !== undefined){
      // console.log("timernotnull",timer)
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{
      if(cal && cal.querySelectorAll('.caldate') && cal.querySelectorAll('.caldate').length > 0){
        // console.log("TAG",tag,cal,cal.querySelectorAll('li'))
        clearTimeout(timer)
        processTags(cal.querySelectorAll('li'))
        return 
      }else{
        // console.log("no cals")
        checkCal(tag)
      }
    },500)
  }


  const getParams = (param)=>{
    param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + param + '=([^&#]*)')
    var results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }

  const nextEvent = ()=>{
    clearTimeout(nextEventTime)
    nextEventTime = setTimeout(()=>{
      if(!inViewport()) return
      gotoEvent((events.length > +active+1 ? +active+1 : 0))
    },5000)
  }

  const prevEvent = ()=>{
    clearTimeout(nextEventTime)
    nextEventTime = setTimeout(()=>{
      if(!inViewport()) return
      gotoEvent((events.length > +active-1 ? +active-1 : events.length))
    },5000)
  }

  const inViewport = ()=>{
    let bounding = elem.getBoundingClientRect();
    let docwidth = (elem.innerWidth || document.documentElement.clientWidth)
    let docheight = (elem.innerHeight || document.documentElement.clientHeight)
    return (bounding.top >= 0 && bounding.left >= 0 && bounding.right <=  docwidth && bounding.bottom <= docheight)
  }

  const gotoEvent = (idx)=>{
    let w = elem.getBoundingClientRect().width < 768 ? (elem.getBoundingClientRect().width + 10) : (elem.getBoundingClientRect().width / 2)
    active = idx
    nextEvent() // resets timer
    let rev = elem.querySelector("#event"+idx)
    elem.scrollTo({left:(w*idx),top:0, behavior: 'smooth'})
  }

  const gotoPrevEvent = ()=>{
    gotoEvent((+active-1 >= 0 ? +active-1 : events.length-1))
  }

  const gotoNextEvent = ()=>{
    gotoEvent((events.length > +active+1 ? +active+1 : 0))
  }

  const handleScroll = (e)=>{
    clearTimeout(scrollDebounce)
    scrollDebounce = setTimeout(()=>{
      let left = elem.scrollLeft
      for(let idx in events){
        if(elem.querySelector(".event"+idx).offsetLeft <= left){
          active = idx
          nextEvent()
        }
      }
    },50)
  }

  const openEvent = (id)=>{
    tags[id].querySelector('.morelink a').click()
    active = id
    nextEvent()
  }

</script>

<div class="events">
  {#if pageedit}
    <p>event carousel</p>
  {:else}
    <div class="hidden" bind:this={node}>
      <slot />
    </div>
    <div class="events-list" bind:this={elem} on:scroll={handleScroll}>
      <div class="event-dates">
        {#each events as {month,date},idx}
          <div class={active == idx ? "active event" : "event"} id={"event"+idx} on:click={()=> gotoEvent(idx)}>
            <div class="event-date">
              <span class="event-month">{month}</span>
              <span class="event-day">{date}</span>
            </div>
          </div>
        {:else}
          <div class="event"><div class="event-date"><p>Loading events...</p></div></div>
        {/each}
      </div>
      <div class="event-infoarea">
        {#each events as {title,description},idx}
          <div class={active == idx ? "active event-info" : "event-info"} on:click={()=> openEvent(idx)}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        {:else}
          <div class="event-info"><p>Loading event details...</p></div>
        {/each}
      </div>
    </div>
    <div class="calendar-link"><a class="btn btn-arrow" href="/events_calendar/">Full calendar</a></div>
    <div class="pager">
      {#each events as event,idx}
        <button class={active == idx ? "active" : ""} on:click={()=> gotoEvent(idx)} />
      {/each}
    </div>
    <div class="prev-event" on:click={()=> gotoPrevEvent()}></div>
    <div class="next-event" on:click={()=> gotoNextEvent()}></div>
  {/if}
</div>

<style>
  .hidden{
    width:0;
    height:0;
    opacity:0;
  }
  .events{
    position:relative;
    max-width:100vw;
    overflow:hidden;
  }
  .events-list{
    max-width: 1200px;
    margin: 30px auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }
  .events-list::-webkit-scrollbar { 
    display: none; 
  }
  .event{
    background:white;
    margin:0 5px;
    flex: 0 0 auto;
    scroll-snap-align: left;
    width:calc(50% - 10px);
    z-index:1;
  }
  @media (max-width:768px){
    .events-list{
      max-width:calc(100% - 60px);
    }
    .event{
      width:100%;
    }
  }
  .event h3{
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid #edeff1;
  }
  .event p{
    margin: 0;
    padding: 20px;
  }
  :global(.event-star){
    background-color: #f0ad4e;
    -webkit-mask: url('/images/assets/icons/star.svg');
    width: 14px;
    height: 12px;
    display: inline-block;
  }
  .event-tick{
    float: right;
    display: inline-block;
    width: 28px;
    height: 28px;
    background: #33ad44;
    border-radius: 100%;
  }
  .event-tick:after{
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin: 5px;
    background-color: white;
    -webkit-mask: url('/images/assets/icons/check.svg');
  }
  .pager{
    text-align: center;
  }
  .pager button{
    border: 0px solid transparent;
    background: rgba(0,0,0,0.2);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 6px;
    padding: 0;
    cursor: pointer;
  }
  .pager button.active{
    background: rgba(0,0,0,1);
  }




  :global(duo-events){
    max-width: 100vw;
    display: block;
  }

  .next-event,.prev-event{
    position: absolute;
    right: calc(50% - 620px);
    top: 40%;
    width: 20px;
    height: 20px;
    border-top: 3px solid #ccc;
    border-right: 3px solid #ccc;
    transform: rotate(45deg);
    transition: all ease 0.2s;
    cursor: pointer;
  }
  .next-event:hover,.prev-event:hover{
    border-color: black;
  }
  .prev-event{
    right: auto;
    left: calc(50% - 620px);
    transform: rotate(-135deg);
  }
  @media (max-width: 1300px){
    .next-event,.prev-event{
      top: auto;
      bottom: 5px;
      right: calc(50% - 100px);
      width: 15px;
      height: 15px;
    }
    .prev-event{
      right: auto;
      left: calc(50% - 100px);
    }
  }


</style>