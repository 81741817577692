<script>
  import {onMount} from 'svelte';
  let channels=['facebook','twitter','instagram','youtube','vimeo'];
  let activechannel,channeltext

  onMount(async () =>{
    channels = ['facebook','twitter','instagram','youtube','vimeo']
    channeltext = document.querySelector('input[name="page.meta.channel"]') || null
    activechannel = channeltext.value || null
    console.log("ac",activechannel)
  });

  const setActiveChannel = (chan)=>{
    if(channeltext != null)
      channeltext.value = chan
      activechannel = chan
  }



</script>

<div class="social-selector">
  {#each channels as channel}
    <span class={activechannel == channel ? 'active' : ''} on:click={()=> setActiveChannel(channel)}>
      <i class="fa fa-{channel}"></i><br>{channel}
    </span>
  {/each}
</div>
