
    var riot = require('riot')
    
riot.tag2('navigation', '<div class="{darken}"><yield></yield></div>', '', '', function(opts) {
this.show = false;

this.darken = "shorten";

this.on('mount', function() {
  return window.addEventListener('scroll', this.handleScroll);
});

this.on('unmount', function() {
  return window.removeEventListener('scroll', this.handleScroll);
});

this.handleScroll = (function(_this) {
  return function() {
    var scroll;
    scroll = window.scrollY || document.documentElement.scrollTop;
    if (scroll > 150 && _this.darken === "shorten") {
      _this.darken = "shorten";
      return _this.update();
    } else if (scroll < 150 && _this.darken === "shorten") {
      _this.darken = "shorten";
      return _this.update();
    }
  };
})(this);

this.toggle = (function(_this) {
  return function() {
    _this.show = !_this.show;
    if (_this.show) {
      document.querySelector('body').classList.add('navshowing');
    } else {
      document.querySelector('body').classList.remove('navshowing');
    }
    return _this.update();
  };
})(this);
});
    
  